import React, { useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Accordion,
  Button,
  ButtonGroup,
  Card,
  Col,
  Row
} from 'react-bootstrap';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { Box } from '../../modules/common/components/box';
import { HSRCharacter } from '../../modules/hsr/common/components/hsr-character';
import { HSRLightCone } from '../../modules/hsr/common/components/hsr-light-cone';
import Countdown from 'react-countdown';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const HSRIndexPage: React.FC = () => {
  const [currentRegion, setCurrentRegion] = useState('NA');
  const renderer = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event ended</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderer2 = ({ days, hours, minutes, completed }) => {
    if (completed) {
      return <span className="time">Event started</span>;
    } else {
      return (
        <span className="time">
          {days >= 1 ? (
            <>
              {days}d {hours}h
            </>
          ) : (
            <>
              {hours}h {minutes}m
            </>
          )}
        </span>
      );
    }
  };
  return (
    <DashboardLayout className={'home-page hsr'} game="hsr">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Honkai: Star Rail wiki and database</h1>
        <h2>
          Prydwen.gg is a wiki and database for Honkai: Star Rail, a HoYoverse
          turn-based space fantasy RPG. Check out our guides, character reviews,
          tier list and more!
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      {/* <SectionHeader title="New content" />
      <div className="promoted-content">
        <div className="banner jade">
          <h4>Jade review and build guide</h4>
          <Link to="/star-rail/characters/jade">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
      </div> */}
      <SectionHeader title="Easyfun - Play Honkai: Star Rail on Browser without downloading!" />
      <div className="banner-ldp-promo ">
        <h4>Play Honkai: Star Rail on Browser</h4>
        <h5>Without downloading - just click to play!</h5>
        <OutboundLink
          href="https://www.easyfun.gg/games/honkai-star-rail.html?from=prydwen"
          target="_blank"
        >
          <Button variant="primary">
            Play now <FontAwesomeIcon icon={faPlay} width="18" />
          </Button>
        </OutboundLink>
      </div>
      <SectionHeader title="Active codes" />
      <div className="codes">
        <Box isCentered>
          <p className="code">STARRAILGIFT</p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 26.04.2023</p>
        </Box>
        <Box isCentered>
          <p className="code">XRPTL1P7K5H8W</p>
          <p className="rewards">Materials + Credits</p>
          <p className="date">Released on 04.09.2024</p>
        </Box>
        <Box isCentered>
          <p className="code">
            AA2G7664C2RX <span className="new">NEW!</span>{' '}
          </p>
          <p className="rewards">50 Stellar Jades + EXP materials</p>
          <p className="date">Released on 10.09.2024</p>
        </Box>
      </div>
      <SectionHeader title={'Event Timeline'} />
      <Row xs={1} xl={2}>
        <Col>
          <div className="tracker-header">
            <h5>Current</h5>
            <ButtonGroup aria-label="region-switcher">
              <Button
                variant="secondary"
                className={`${currentRegion === 'NA' && 'active'}`}
                onClick={() => setCurrentRegion('NA')}
              >
                NA
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'EU' && 'active'}`}
                onClick={() => setCurrentRegion('EU')}
              >
                EU
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'Asia' && 'active'}`}
                onClick={() => setCurrentRegion('Asia')}
              >
                Asia
              </Button>
            </ButtonGroup>
          </div>
          <Accordion className="event-tracker">
            {/* <Accordion.Item className="garden" eventKey="445">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Garden of Plenty</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1725613200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1725591600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1725566400000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/08/30 04:00:00 – 2024/09/06
                  04:00:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Golden and Crimson
                  Calyx will give double rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item> */}
            <Accordion.Item className="pure-fiction" eventKey="2243">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Pure Fiction (2.4)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727686800000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727665200000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727640000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/08/19 04:00:00 – 2024/09/30
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: When allies use an Ultimate to
                  attack targets, inflict Shatter to the targets for up to 6
                  stacks. At the start of the target's turn or when the target
                  is eliminated, deals a set amount of DMG to the target and
                  adjacent targets based on the number of Shatter stacks.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="feixiao" eventKey="2501">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Stormrider's Bolt</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727881200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727859600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727838000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.5 maintenance -
                  2024/10/02 11:59:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="feixiao"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="I Venture Forth to Hunt" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="moze"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="asta"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="luka"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Swordplay" />
                  <HSRLightCone
                    mode="profile"
                    name="Resolution Shines As Pearls of Sweat"
                  />
                  <HSRLightCone mode="profile" name="The Birth of the Self" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="kafka" eventKey="2502">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Indelible Coterie I</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727881200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727859600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727838000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.5 maintenance -
                  2024/10/02 11:59:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="kafka"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone
                    mode="profile"
                    name="Patience Is All You Need"
                  />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="moze"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="asta"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="luka"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Swordplay" />
                  <HSRLightCone
                    mode="profile"
                    name="Resolution Shines As Pearls of Sweat"
                  />
                  <HSRLightCone mode="profile" name="The Birth of the Self" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="swan" eventKey="2503">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Indelible Coterie II</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727881200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727859600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727838000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.5 maintenance -
                  2024/10/02 11:59:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="black-swan"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Reforged Remembrance" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="moze"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="asta"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="luka"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Swordplay" />
                  <HSRLightCone
                    mode="profile"
                    name="Resolution Shines As Pearls of Sweat"
                  />
                  <HSRLightCone mode="profile" name="The Birth of the Self" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="robin" eventKey="2504">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Indelible Coterie III</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727881200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727859600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727838000000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.5 maintenance -
                  2024/10/02 11:59:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="robin"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Flowing Nightglow" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="moze"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="asta"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="luka"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Swordplay" />
                  <HSRLightCone
                    mode="profile"
                    name="Resolution Shines As Pearls of Sweat"
                  />
                  <HSRLightCone mode="profile" name="The Birth of the Self" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="memory-of-chaos" eventKey="2241">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Memory of Chaos (2.4)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1728896400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1728874800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1728849600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/09/02 04:00:00 – 2024/10/14
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: After every attack launched by
                  an ally, apply 1 stack of Memory Imprint to all allies. Allies
                  in the Memory Imprint state deal 8% more DMG. This effect
                  stacks up to 6 time(s). When Memory Imprint reaches max
                  stacks, immediately deals 1 instance of DMG to all enemies
                  based on a certain percentage of their respective Max HP.
                  Remove all Memory Imprints from all allies at the start of
                  every wave or Cycle
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-1-25" eventKey="25001">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Luminary Wardance Ceremony</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1729501200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1729479600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1729454400000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.5 maintenance -
                  2024/10/21 03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Event description</span>: Take the role of being Luka's
                  coach and complete missions such as Wardance Schedule to
                  obtain various rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="nameless-honor" eventKey="992">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Nameless Honor</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1729501200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1729479600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1729454400000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.5 update - 2024/10/21
                  3:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Trailblazers can
                  increase the level of Nameless Honor to obtain various
                  rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="odyssey" eventKey="998">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Gift of Odyssey</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1729501200000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1729479600000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1729454400000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: After 2.5 update - 2024/10/21
                  3:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: Trailblazers can receive daily
                  rewards in the Travel Log each day after logging in to the
                  game. Rewards will be unlocked in sequential order according
                  to the accrued login days of the Trailblazer.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="apo" eventKey="2242">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Apocalyptic Shadow (2.5)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1730109600000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1730088000000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1730062800000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/09/16 04:00:00 – 2024/10/28
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: When an Enemy unit with
                  Steadfast Safeguard is Weakness Broken, advance all Party
                  characters' action and recover Skill Points. Follow-up attack
                  DMG taken by all Enemy units increases by 25% and Ultimate DMG
                  taken increases by 15%.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col>
          <div className="tracker-header">
            <h5>Upcoming</h5>
            <ButtonGroup aria-label="region-switcher">
              <Button
                variant="secondary"
                className={`${currentRegion === 'NA' && 'active'}`}
                onClick={() => setCurrentRegion('NA')}
              >
                NA
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'EU' && 'active'}`}
                onClick={() => setCurrentRegion('EU')}
              >
                EU
              </Button>
              <Button
                variant="secondary"
                className={`${currentRegion === 'Asia' && 'active'}`}
                onClick={() => setCurrentRegion('Asia')}
              >
                Asia
              </Button>
            </ButtonGroup>
          </div>
          <Accordion className="event-tracker">
            <Accordion.Item className="planar-fissure" eventKey="44412">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Planar Fissure</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727082000000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727060400000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727035200000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/09/23 04:00:00 – 2024/09/30
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, Planar Ornaments
                  obtained from the immersion device will be doubled when
                  challenging the Simulated Universe. The number of daily double
                  reward opportunities is limited and can be viewed on the
                  Planar Fissure event page.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="event-2-25" eventKey="25002">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Festive Revelry</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727686800000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727665200000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727640000000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/09/30 12:00:00 - 2024/10/21
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Event description</span>: Experience the temple fair to
                  get various rewards.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="pure-fiction" eventKey="2243">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Pure Fiction (2.5)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727686800000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727665200000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727640000000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/09/30 04:00:00 – 2024/11/11
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: When allies Break enemy
                  targets' Weakness, charge Whimsicality. When charged to 6
                  points, deals a set amount of DMG to random enemies 10
                  time(s).
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="lingsha" eventKey="2505">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Let Scent Sink In</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727881200000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727859600000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727838000000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/10/02 12:00:00 - 2024/10/22
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="lingsha"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Scent Alone Stays True" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="misha"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="natasha"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="guinaifen"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Shared Feeling" />
                  <HSRLightCone mode="profile" name="Shadowed By Night" />
                  <HSRLightCone mode="profile" name="Planetary Rendezvous" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="topaz" eventKey="2506">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Numby (and Topaz)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1727881200000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1727859600000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1727838000000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/10/02 12:00:00 - 2024/10/22
                  15:00:00 (server time)
                </p>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> character:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="topaz"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-5">5★</span> Light Cone:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Worrisome, Blissful" />
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> characters:
                </p>
                <div className="featured-characters">
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="misha"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="natasha"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                  <Card className="avatar-card">
                    <HSRCharacter
                      slug="guinaifen"
                      mode="icon"
                      showIcon
                      enablePopover
                    />
                  </Card>
                </div>
                <p className="featured">
                  Featured <span className="hsr-rar rar-4">4★</span> Light
                  Cones:
                </p>
                <div className="featured-cone">
                  <HSRLightCone mode="profile" name="Shared Feeling" />
                  <HSRLightCone mode="profile" name="Shadowed By Night" />
                  <HSRLightCone mode="profile" name="Planetary Rendezvous" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="realm" eventKey="444131">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Realm of the Strange</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1728637200000} renderer={renderer2} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1728615600000} renderer={renderer2} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1728590400000} renderer={renderer2} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/10/11 04:00:00 – 2024/10/28
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Description</span>: During the event, the amount of
                  Relics obtained from the Cavern of Corrosion will be doubled.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="memory-of-chaos" eventKey="2241">
              <Accordion.Header as="div">
                <div className="overlay"></div>
                <div className="event-name">Memory of Chaos (2.5)</div>
                <div className="countdown">
                  {currentRegion === 'NA' && (
                    <Countdown date={1728896400000} renderer={renderer} />
                  )}
                  {currentRegion === 'EU' && (
                    <Countdown date={1728874800000} renderer={renderer} />
                  )}
                  {currentRegion === 'Asia' && (
                    <Countdown date={1728849600000} renderer={renderer} />
                  )}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <p className="duration">
                  <span>Event Duration</span>: 2024/10/14 04:00:00 – 2024/11/25
                  03:59:00 (server time)
                </p>
                <p className="description">
                  <span>Memory Turbulence</span>: Enemies have Exo-Toughness and
                  their actions are delayed by 30% after they are Weakness
                  Broken. At the same time, add 1 hit(s) to "Memory
                  Turbulence"'s Hits Per Action. If the target is an Elite enemy
                  or higher, then additionally add 2 hit(s), up to a max of 10
                  hits. At the start of every Cycle, every hit by the "Memory
                  Turbulence" causes 1 instance of DMG, dealing a set amount of
                  DMG to random enemy targets.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      <br />
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/star-rail/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_introgame.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/star-rail/guides/reroll"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_rerollguide.jpg"
              alt="Reroll"
            />
          }
        />
        <CategoryCard
          title="Tier list"
          link="/star-rail/tier-list"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_tierlist.png"
              alt="Tier list"
            />
          }
        />
        <CategoryCard
          title="Free characters"
          link="/star-rail/guides/free-characters"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_freechars.png"
              alt="Free characters"
            />
          }
        />
        <CategoryCard
          title="Characters"
          link="/star-rail/characters"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_characters.png"
              alt="Characters"
            />
          }
        />
        <CategoryCard
          title="Early progression tips"
          link="/star-rail/guides/early-progression"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_earlyprog.png"
              alt="Early progression tips"
            />
          }
        />
        <CategoryCard
          title="Team building"
          link="/star-rail/guides/team-building"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_team.png"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Light Cones"
          link="/star-rail/guides/light-cones"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_cones_guide.png"
              alt="Light Cones"
            />
          }
        />
        <CategoryCard
          title="Relics"
          link="/star-rail/guides/relics"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_relicinfo.png"
              alt="Relics"
            />
          }
        />
        <CategoryCard
          title="Memory of Chaos Analytics"
          link="/star-rail/memory-of-chaos"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_fh.png"
              alt="Memory of Chaos"
            />
          }
        />
        <CategoryCard
          title="Pure Fiction Analytics"
          link="/star-rail/pure-fiction"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_pf.jpg"
              alt="Pure Fiction"
            />
          }
        />
        <CategoryCard
          title="Apocalyptic Shadow Analytics"
          link="/star-rail/apocalyptic-shadow"
          image={
            <StaticImage
              src="../../images/starrail/categories/category_apo.jpg"
              alt="Apocalyptic Shadow"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
    </DashboardLayout>
  );
};

export default HSRIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Honkai: Star Rail Wiki and Database | Prydwen Institute"
    description="Prydwen.gg is a wiki and database for Honkai: Star Rail, a HoYoverse turn-based space fantasy RPG. Check out our guides, character reviews, tier list and more!"
  />
);
